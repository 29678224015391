import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
const EditCameraImageModal = (props) => {
  const {
    showEditCameraImageModal,
    disableButton,
    cameraImageSrc,
    sendCameraImageOnServer,
    closeEditCameraImageModal,
  } = props;

  /*const "showCroppedImage" show cropped image */
  const [showCroppedImage, setshowCroppedImage] = useState(false);

  /* const "cropper" store cropper of image */
  const [cropper, setCropper] = useState();

  /* const  "getCameraImageSrc" store src for click image*/
  const [getCameraImageSrc, setgetCameraImageSrc] = useState(cameraImageSrc);

  /**
   * This function will open image on edit mode
   */
  const editImage = () => {
    if (showCroppedImage === false) {
      setshowCroppedImage(true);
    } else {
      setshowCroppedImage(false);
    }
  };

  /**
   * This funciton will rotate the image
   */
  const rotateImage = () => {
    if (cropper) {
      cropper.rotate(90);
    }
  };

  /**
   * This funcntion will save the edit image
   */
  const saveEditImage = () => {
    const imgSrc = cropper.getCroppedCanvas().toDataURL();
    setgetCameraImageSrc(imgSrc);
    setshowCroppedImage(false);
  };

  /**
   * This function will manage cropper
   * @param {object} instance
   */
  const manageCropper = (instance) => {
    setCropper(instance);
  };

  /**
   * This funciton will convert data url to file
   * @param {string} dataurl
   * @param {string} filename
   */
  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
   * This function will upload image on server
   */
  const uploadImage = () => {
    if (getCameraImageSrc) {
      let file = dataURLtoFile(
        getCameraImageSrc,
        `image${Math.floor(1000 + Math.random() * 9000)}.jpeg`
      );
      sendCameraImageOnServer(file);
    }
  };

  return (
    <Modal
      isOpen={showEditCameraImageModal}
      size="lg"
      style={{ maxWidth: "700px", width: "100%" }}
    >
      <ModalHeader toggle={closeEditCameraImageModal}>
        <p>Upload Media</p>
      </ModalHeader>
      <ModalBody>
        <div className="img-action mt-1">
          <span className="custom-btn mr-1" onClick={() => editImage()}>
            <i className="fas fa-crop-alt"></i>
          </span>
        </div>
        {showCroppedImage ? (
          <div>
            <Cropper
              className="clickImage_container"
              movable={true}
              zoomable={true}
              dragMode="move"
              zoomOnTouch={true}
              zoomOnWheel={true}
              initialAspectRatio={1}
              src={cameraImageSrc}
              viewMode={2}
              rotatable={true}
              minCropBoxHeight={40}
              minCropBoxWidth={40}
              background={true}
              responsive={true}
              cropBoxMovable={false}
              autoCrop={true}
              autoCropArea={1}
              modal={true}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => manageCropper(instance)}
              guides={true}
              center={true}
              highlight={false}
              dragMode="move"
            />
            <div className="text-center">
              <Button onClick={rotateImage} className="btn-sm custom-btn">
                <i className="fas fa-sync"></i>
              </Button>
              <Button
                onClick={() => saveEditImage()}
                className="btn-sm custom-btn"
              >
                <i className="fas fa-check"></i>
              </Button>
            </div>
          </div>
        ) : (
          <div className="clickImage_container">
            <img src={getCameraImageSrc} alt="img" />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={uploadImage}
          disabled={disableButton}
          className="custom-btn-fill"
          size="sm"
        >
          {disableButton ? "Uploading..." : "Upload"}
        </Button>
        <Button
          onClick={closeEditCameraImageModal}
          className="custom-btn"
          size="sm"
        >
          {" "}
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditCameraImageModal;
