import React, { useState, useEffect } from "react";
import { authentication } from "../firebase/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import PhoneInput from "react-phone-input-2";
import { phone } from "phone";
import { Button } from "reactstrap";
import logo from "../assets/images/NewLogo.png";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Spinner } from "reactstrap";
import {
  setCurrentDeviceToken,
  setSessionId,
  hidehistoryDetailOnMobile,
} from "../redux";
import { connect } from "react-redux";
const Login = (props) => {

  const navigate = useNavigate();

  /* const "mynumber" store phone number of user */
  const [myNumber, setmyNumber] = useState(null);

  /* store "otp" enter by user */
  const [otp, setotp] = useState();

  /* const "show" responsible for showing login and password screen */
  const [show, setshow] = useState(false);

  /* const "final" store output of after send otp */
  const [final, setfinal] = useState(null);

  /* const "error" store flag of error*/
  const [error, seterror] = useState(false);

  /* const "toogle" store onblur event status*/
  const [toogle, settoogle] = useState(false);

  /* const "seconds" store second of timer */
  const [seconds, setSeconds] = useState(30);

  /* const "diableButtton" store button disable status  */
  const [diableButtton, setdiableButtton] = useState(true);

  /* const "disableSignInButton" disable singIn button */
  const [disableSignInButton, setdisableSignInButton] = useState(true);

  /* const "loading" show loader status   */
  const [loading, setloading] = useState();

  /* const "showIncorrectOtpError" show error show error for inccorect otp */
  const [showIncorrectOtpError, setshowIncorrectOtpError] = useState(false);

  /* const "ip" store ip address */
  const [ip, setIP] = useState("");

  /* const "dialCode" store dial code of country  */
  const [dialCode, setdialCode] = useState("+1");

  /* const "singInLoading" show loader for singin button */
  const [singInLoading, setsingInLoading] = useState(false);

  /* cosnt "otpLoader" loader for otp button*/
  const [otpLoader, setOTPLoader] = useState(false);

  useEffect(() => {
    props.hidehistoryDetailOnMobile();
    let userId = localStorage.getItem("userId");
    if (userId) {
      setloading(false);
      navigate("/memory");
    } else {
      setloading(true);
    }
    getIpAddress();
  }, []);

  useEffect(() => {
    if (show) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      }
    }
  }, [show, seconds]);

  /**
   * This function will give IP Address
   */
  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    setIP(res.data.IPv4);
  };

  const notify = () => {
    return toast.success("OTP send successfully", {
      className: "toast-container_OTP",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  /**
   * This Function handle phone Input field
   * @param {string} phonenumber
   * @param {object} value
   */
  const handleInputOnChange = (phonenumber, value) => {
    setdialCode(`+${value.dialCode}`);
    const newNumber = "+" + phonenumber;
    if (newNumber !== myNumber) {
      setmyNumber("+" + phonenumber);
      const phoneNumberStatus = phone(phonenumber, {
        country: value.countryCode,
      });
     
      if (phoneNumberStatus.isValid) {
        seterror(false);
        setdiableButtton(false);
      } else {
        const verify = new RecaptchaVerifier(
          "recaptcha-container",
          {},
          authentication
        );
        setdiableButtton(true);
        seterror(true);
      }
    }
  };

  /**
   * This Function will send OTP on Enter Number
   * @returns
   */
  const sendOTP = () => {
    setOTPLoader(true);
    if (myNumber === "") return;
    setdiableButtton(true);
    const verify = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      authentication
    );
    signInWithPhoneNumber(authentication, myNumber, verify)
      .then((result) => {
        setOTPLoader(false);
        setfinal(result);
        setshow(true);
        notify();
        verify.clear();
        setdiableButtton(false);
      })
      .catch((err) => {
        console.log(err);
        setOTPLoader(false);
        navigate("/memory");
        toast.error(err.message, {
          className: "toast-container_OTP",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setdiableButtton(false);
      });
  };

  /**
   * This Function handle Enter OTP
   * @param {object} e
   */
  const handleOTP = (e) => {
    setotp(e.target.value);
    setshowIncorrectOtpError(false);
    if (e.target.value.length === 6) {
      setdisableSignInButton(false);
    } else {
      setdisableSignInButton(true);
    }
  };

  /**
   * This Function check OTP is valid or not
   * @returns
   */
  const validateOTP = () => {
    if (otp === null || final === null) return;
    setdisableSignInButton(true);
    setsingInLoading(true);

    final
      .confirm(otp)
      .then((result) => {
        setshowIncorrectOtpError(false);
        if (result.user?.uid) {
          let number = result.user.phoneNumber;
          const updateNumber = number.replace(dialCode, "");
          const dataObj = {
            firebaseToken: result.user?.uid,
            countryCode: dialCode,
            phoneNumber: updateNumber,
          };

          const BASE_URL = process.env.REACT_APP_BASE_URL || "";
          const LOGIN_URL = BASE_URL + "login";
          const header = {
            "content-type": "application/json",
            ip_address: ip,
            platform: "web",
            app_version: 1,
            device_token: props.currentDeviceToken,
            language: "english",
          };
          fetch(LOGIN_URL, {
            method: "post",
            headers: header,
            body: JSON.stringify(dataObj),
          })
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              if (response.status === 200) {
                setdisableSignInButton(false);
                setsingInLoading(false);
                props.setSessionId(response.session_id);
                localStorage.setItem("userId", response.user_id);
                localStorage.setItem("sessionId", response.session_id);
                navigate("/memory");
              }
            })
            .catch((error) => {
              setdisableSignInButton(false);
              setsingInLoading(false);
              toast.error("SomeThing went wrong", {
                className: "toast-container_OTP",
                position: "top-right",
                autoClose: 3000,
              });
            });
        }
      })
      .catch((err) => {
        setdisableSignInButton(false);
        setsingInLoading(false);
        setshowIncorrectOtpError(true);
      
      });
  };

  /**
   * This Function resend OTP on number
   */
  const resendOTP = () => {
    if (myNumber) {
      const verifyResent = new RecaptchaVerifier(
        "sign-in-button",
        { size: "invisible" },
        authentication
      );
      signInWithPhoneNumber(authentication, myNumber, verifyResent)
        .then((result) => {
          setfinal(result);
          notify();
          verifyResent.clear();
          setSeconds(30);
        })
        .catch((err) => {
          toast.success("something went wrong", {
            className: "toast-container_OTP",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          verifyResent.clear();
        });
    }
  };

  return (
    <div>
      {loading && (
        <div className="login-wrap">
          <div className="login-page">
            <div className="login-left"></div>
            <div className="login-right">
              <div>
                <h4 className="mb-3">
                  <img src={logo} width="180" />
                </h4>
                <div className="mb-4 text-secondary">
                  This website give each of us the brain we wish we had.You can
                  store every little thing that occurs to you during the day,and
                  then keep it here forever
                </div>

                {!show && (
                  <div>
                    <div className="container p-0">
                      <div className="inputContainer">
                        <strong>Enter Phone Number</strong>
                        <PhoneInput
                          style={{ marginTop: "5px", width: "100%" }}
                          country={"us"}
                          value={myNumber}
                          placeholder="Phone Number"
                          countryCodeEditable={false}
                          enableSearch={true}
                          onChange={(phonenumber, value) =>
                            handleInputOnChange(phonenumber, value)
                          }
                          onBlur={() => settoogle(true)}
                        />
                        <div style={{ marginBottom: "20px" }}>
                          {toogle && error && (
                            <p className="text-danger f-12">
                              Please enter valid number
                            </p>
                          )}
                        </div>
                        <div id="recaptcha-container"></div>
                      </div>
                    </div>
                    <Button
                      onClick={sendOTP}
                      className="custom-btn-fill w-100 m-0 mt-2"
                      disabled={diableButtton}
                    >
                      {otpLoader ? (
                        <div
                          className="d-flex align-items-center justify-content-center "
                          style={{ margin: "4px 0px" }}
                        >
                          <Spinner animation="grow" size="sm" />
                        </div>
                      ) : (
                        "Send OTP"
                      )}
                    </Button>
                  </div>
                )}
                {show && (
                  <div>
                    <div className="container p-0">
                      <div className="inputContainer">
                        <strong>Enter OTP</strong>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            style={{ marginTop: "5px" }}
                            placeholder={"OTP"}
                            onChange={handleOTP}
                          />
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                          {showIncorrectOtpError && (
                            <p className="text-danger f-12">
                              The entered OTP is incorrect. Please try again
                            </p>
                          )}
                        </div>
                        <div>
                          <Button
                            onClick={validateOTP}
                            className="custom-btn-fill w-100 m-0 mt-2"
                            disabled={disableSignInButton}
                          >
                            {singInLoading ? (
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ margin: "4px 0px" }}
                              >
                                <Spinner animation="grow" size="sm" />
                              </div>
                            ) : (
                              "Sign In"
                            )}
                          </Button>

                          {seconds === 0 ? (
                            <div className="text-center mt-2">
                              <button
                                onClick={resendOTP}
                                id="sign-in-button"
                                className="resentOtp"
                              >
                                Resend OTP
                              </button>
                            </div>
                          ) : (
                            <div className="text-center mt-2">
                              Resend OTP in {seconds} sec
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentDeviceToken: state.history?.currentDeviceToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentDeviceToken: (currentDeviceToken) =>
      dispatch(setCurrentDeviceToken(currentDeviceToken)),
    setSessionId: (sessionId) => dispatch(setSessionId(sessionId)),
    hidehistoryDetailOnMobile: () => dispatch(hidehistoryDetailOnMobile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
