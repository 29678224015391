import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./auth/Login";
import UserMemory from "./history/UserMemory";
import { ToastContainer } from "react-toastify";
import UserMemoryDetail from "./history/UserMemoryDetail";
import DetailHeader from "./history/DetailHeader";
import { Col } from "reactstrap";
import ReminderHistory from "./reminder/ReminderHistory";
import { setCurrentDeviceToken } from "./redux";
import { requestForToken } from "./firebase/firebase";
import { connect } from "react-redux";
import { updateNotificationToken } from "./service/UserService";
import { toast } from "react-toastify";

const App = (props) => {
  
  const [currentToken, setcurrentToken] = useState(null);

  useEffect(() => {
    requestForToken(setcurrentToken);
  }, []);

  useEffect(() => {
    let sessionId = localStorage.getItem("sessionId");
    if (currentToken) {
      props.setCurrentDeviceToken(currentToken);
    }
    if (sessionId) {
      const dataObj = {
        sessionId: sessionId,
        deviceToken: currentToken,
      };
      updateNotificationToken(dataObj)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
          } else {
            toast.error("Something went wrong", {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          toast.error("Something went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    }
  }, [currentToken]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="memory" element={<UserMemory />}>
          <Route
            index
            element={
              <Col lg="9" md="8" className="col-12">
                <DetailHeader />
              </Col>
            }
          />
          <Route path="reminders" element={<ReminderHistory />} />
          <Route path=":memoryId" element={<UserMemoryDetail />} />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>

      <ToastContainer
        toastStyle={{ backgroundColor: "#636ac6", color: "white" }}
        position="top-right"
        autoClose={3000}
        pauseOnHover={false}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentDeviceToken: state.history?.currentDeviceToken,
    sessionId: state.history?.sessionId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentDeviceToken: (currentDeviceToken) =>
      dispatch(setCurrentDeviceToken(currentDeviceToken)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
