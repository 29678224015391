import React, { useState, useEffect } from "react";
import { allMemory, deleteMemory } from "../service/MemoryService";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Col, Spinner } from "reactstrap";
import { addMemory } from "../service/MemoryService";
import fileImg from "../assets/images/fileImg.png";
import favicon from "../assets/images/favicon.png"
import ShowMemoryStatus from "./ShowMemoryStatus";
import { connect } from "react-redux";
import { showMemoryNameInEditMode } from "../redux";
import moment from "moment";
import DeleteMemory from "./DeleteMemory";
import { onMessageListener } from "../firebase/firebase";
import { toast } from "react-toastify";

const UserMemory = (props) => {

  const navigate = useNavigate();

  const location = useLocation();

  let params = useParams();

  const memoryId = params.memoryId;

  /* const "userId" store user Id */
  const [userId, setuserId] = useState("");

  /* const "loading" store loading status of data */
  const [loading, setloading] = useState(true);

  /* const "userAllMemory" store all memory of user include Note and list */
  const [userAllMemory, setuserAllMemory] = useState([]);

  /* const "searchKeyword" store search keyword */
  const [searchKeyword, setsearchKeyword] = useState("");

  /* const "showNoRecord" store no record status */
  const [showNoRecord, setshowNoRecord] = useState(false);

  /* const "deletedType"  delete type of Memory */
  const [deletedMemoryType, setdeletedMemoryType] = useState("");

  /* const "deletedIndex" store index of delete Memory */
  const [deletedMemoryIndex, setdeletedMemoryIndex] = useState(null);

  /* const "deletedMemoryId" store MemoryId of delete Memory  */
  const [deletedMemoryId, setdeletedMemoryId] = useState("");

  /* const "showDeleteMemoryModal" responsible for showing and hide of delete modal */
  const [showDeleteMemoryModal, setshowDeleteMemoryModal] = useState(false);

  /* const "deleteLoader"  store loader status for delete button */
  const [deleteLoader, setdeleteLoader] = useState(false);

  /* const "showMatchingTextOfDetail" show and hide matching text of search on detail page */
  const [showMatchingTextOfDetail, setshowMatchingTextOfDetail] = useState(
    false
  );

  /* cosnt "notificaitonMemoryId" store notification id */
  const [notificaitonMemoryId, setnotificaitonMemoryId] = useState("");

  /* cosnt "notificaitonMemoryType" notification memory type */
  const [notificaitonMemoryType, setnotificaitonMemoryType] = useState("");

  /* const "notification" store notification */
  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    if (props.userData?.length > 0) {
      setuserAllMemory(props.userData);
    }
  }, [props.userData]);
  
  useEffect(() => {
    const deviceOfType = deviceType();
    console.log("Notification" in window);
    if (notification?.title) {
      if (deviceOfType==="web"){
        let title = notification?.title;
        var options = {
         body: notification?.body,
         icon:favicon
        }
        new Notification(title, {options});
        letsToast();  
      }else{
        letsToast();
      }
    }
  }, [notification]);

  useEffect(() => {
    let userId = localStorage.getItem("userId");
    if (userId) {
      setuserId(userId);
      getUserAllMemory(userId);
    } else {
      navigate("/");
    }
  }, [searchKeyword]);

  /**
   * 
   * @returns This funciton will return custom toast
   */
  const CustomToastWithLink = () => (
    <div className="d-flex align-items-center" className="open_memory_button">
      <div style={{ marginRight: "20px" }} className="text-truncate-one">
        Today {moment(new Date()).format("hh:mm")} {notification?.body}
      </div>
       <div>
        <Link
          to={`/memory/${notificaitonMemoryId}`}
          state={{
            memoryNameInEditMode: false,
            type: notificaitonMemoryType,
          }}
        >
          <div>Open {notificaitonMemoryType === "list" ? "List" : "Note"}</div>
        </Link>
      </div>
    </div>
  );

  const letsToast = () => {
    toast.success(CustomToastWithLink, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  };

  /**
   * This  fucntion will give all Memory
   * @param {string} userId
   */
  const getUserAllMemory = (userId) => {
    const dataObj = {
      userId: userId,
      offset: "",
      limit: "",
      keyword: searchKeyword,
    };
    allMemory(dataObj)
      .then((response) => {
        if (response.status === 200) {
          setuserAllMemory(response.data);
          const deviceOfType = deviceType();
          if (
            response.data?.length > 0 &&
            deviceOfType !== "mobile" &&
            location.pathname === "/memory"
          ) {
            navigate(`/memory/${response.data[0].memory_id}`, {
              state: {
                type: response.data[0].type,
              },
            });
          }
          setloading(false);
        } else {
          toast.error("Some Thing went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        toast.error("Some Thing went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  onMessageListener()
    .then((payload) => {
      setnotificaitonMemoryId(payload?.data?.memoryId);
      setnotificaitonMemoryType(payload?.data.type);
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) =>{
     console.log("failed: ", err);
     console.log("failed: ", err);
     console.log("failed: ", err);
    }
     );

  /**
   * This function will add new List
   */
  const createList = () => {
    let dataObj = new FormData();
    dataObj.append("userId", userId);
    dataObj.append("memoryText", "New List");
    dataObj.append("type", "list");
    addMemory(dataObj)
      .then((response) => {
        if (response.status === 200) {
          getUpdatedMemory(0, true);
        } else {
          toast.error("Some Thing went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        toast.error("Some Thing went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * This function will add new Note
   */
  const createNote = () => {
    let dataObj = new FormData();
    dataObj.append("userId", userId);
    dataObj.append("memoryText", "New Note");
    dataObj.append("type", "memory");
    addMemory(dataObj)
      .then((response) => {
        if (response.status === 200) {
          getUpdatedMemory(0, true);
        } else {
          toast.error("Some Thing went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        toast.error("Some Thing went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   *
   * @returns This function return device type its mobile,laptop,desktop
   */
  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  /**
   * This function update histoy after add and update
   * @param {number} index
   */
  const getUpdatedMemory = (index, toggle) => {
    const deviceOfType = deviceType();
    const dataObj = {
      userId: userId,
      offset: "",
      limit: "",
      keyword: "",
    };
    allMemory(dataObj)
      .then((response) => {
        setuserAllMemory(response.data);
        setshowDeleteMemoryModal(false);
        setdeleteLoader(false);
        if (response.data?.length === 0) {
          navigate("/memory");
        } else {
          if (response.data?.length > index && deviceOfType !== "mobile") {
            navigate(`/memory/${response.data[index].memory_id}`, {
              state: {
                memoryNameInEditMode: index === 0 && toggle ? true : false,
                type: response.data[index].type,
              },
            });
          } else {
            if (deviceOfType !== "mobile") {
              navigate(`/memory/${response.data[index - 1].memory_id}`, {
                state: {
                  memoryNameInEditMode: false,
                  type: response.data[index - 1].type,
                },
              });
            }
          }
        }
      })
      .catch((error) => {
        toast.error("Some Thing went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * handle search string
   * @param {object} e
   */
  const onChangeSearch = (e) => {
    if (e.target.value === "") {
      setshowMatchingTextOfDetail(false);
    } else {
      setshowMatchingTextOfDetail(true);
    }
    setsearchKeyword(e.target.value);
    setshowNoRecord(true);
  };

  /**
   * This function will open delete modal
   * @param {string} memoryType
   * @param {string} memoryId
   * @param {number} index
   */
  const openDeleteMemoryModal = (memoryType, memoryId, index) => {
    setdeletedMemoryType(memoryType);
    setdeletedMemoryIndex(index);
    setdeletedMemoryId(memoryId);
    setshowDeleteMemoryModal(true);
  };

  /**
   * This function will delete Memory
   */
  const handleDeleteMemory = () => {
    setshowNoRecord(false);
    setdeleteLoader(true);
    deleteMemory(deletedMemoryId)
      .then((response) => {
        if (response.status === 200) {
          getUpdatedMemory(deletedMemoryIndex, false);
        } else {
          setshowDeleteMemoryModal(false);
          setdeleteLoader(false);
          toast.error("Some Thing went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        setshowDeleteMemoryModal(false);
        setdeleteLoader(false);
        toast.error("Some Thing went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  return (
    <div>
      {loading ? (
        <div className="custom-loader">
          <Spinner animation="grow" size="sm" />
        </div>
      ) : (
        <div className="d-flex justify-content-between notes_container">
          <Col
            lg="3"
            md="4"
            sm="12"
            className={
              props.showDetailpage
                ? "mobile-collapse col-12"
                : "showmobile-collapse col-12"
            }
          >
            <div className="d-flex justify-content-end align-items-center ">
              <div
                onClick={createList}
                className="d-flex align-items-center flex-direction-row add_New w-50"
                style={{ borderRight: "1px solid rgb(221 221 221)" }}
              >
                <i className="fas fa-plus" />
                <div>New List</div>
              </div>
              <div
                onClick={createNote}
                className="d-flex align-items-center flex-direction-row add_New w-50"
              >
                <i className="fas fa-plus" />
                <div>New Note</div>
              </div>
            </div>
            {userAllMemory?.length > 0 || showNoRecord === true ? (
              <div className="search-list d-flex justify-content-end align-items-center">
                <i className="fas fa-search" />
                <input
                  type="search"
                  className="form-control ml-1"
                  value={searchKeyword}
                  onChange={(e) => onChangeSearch(e)}
                  placeholder="Search"
                />
              </div>
            ) : (
              ""
            )}
            <div className="all_history_container ">
              {userAllMemory.length > 0 ? (
                userAllMemory.map((memory, index) => {
                  return (
                    <div
                      className={
                        memoryId === memory.memory_id
                          ? "history_container_background"
                          : "history_container"
                      }
                    >
                      <div>
                        {memory.type == "list" ? (
                          <i className="fas fa-list-ul" alt="i"></i>
                        ) : (
                          <img
                            src={fileImg}
                            alt="image"
                            style={{ width: "14px" }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <nav className="navLink_container">
                          <Link
                            to={`/memory/${memory.memory_id}`}
                            state={{
                              memoryNameInEditMode: false,
                              type: memory.type,
                            }}
                          >
                            <div className="d-flex flex-column">
                              <div className="text-truncate">
                                {memory.memory_text === ""
                                  ? memory.type === "list"
                                    ? "New List"
                                    : "New Note"
                                  : memory.memory_text}
                              </div>

                              <div className="d-flex">
                                <div
                                  style={{
                                    flex: "1",
                                    fontSize: "12px",
                                    color: "#6e6e6e",
                                  }}
                                  className="text-truncate-one"
                                >
                                  {memory.section?.length > 0 &&
                                  showMatchingTextOfDetail
                                    ? memory.section.map((section, index) => {
                                        return (
                                          <span key={index}>
                                            {section.section_name + " : "}
                                            {section.item?.length > 0 &&
                                              section.item.map(
                                                (item, index) => {
                                                  return (
                                                    <span key={index}>
                                                      {(index > 0
                                                        ? " , "
                                                        : "") + item}
                                                    </span>
                                                  );
                                                }
                                              )}
                                          </span>
                                        );
                                      })
                                    : ""}
                                </div>
                                <div
                                  style={{
                                    alignSelf: "end",
                                    fontSize: "12px",
                                    color: "#6e6e6e",
                                  }}
                                >
                                  {moment(memory.updated_at).isValid() &&
                                    moment(memory.updated_at).fromNow(true)}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </nav>
                      </div>
                      <div
                        onClick={() =>
                          openDeleteMemoryModal(
                            memory.type,
                            memory.memory_id,
                            index
                          )
                        }
                        className="delete_history_btn"
                      >
                        <i className="far fa-trash-alt text-danger"></i>
                      </div>
                    </div>
                  );
                })
              ) : (
                <ShowMemoryStatus showNoRecord={showNoRecord} />
              )}
            </div>
            {showDeleteMemoryModal && (
              <DeleteMemory
                showDeleteMemoryModal={showDeleteMemoryModal}
                deletedMemoryType={deletedMemoryType}
                deleteLoader={deleteLoader}
                handleDeleteMemory={handleDeleteMemory}
                closeDeleteMemoryModal={() => setshowDeleteMemoryModal(false)}
              />
            )}
          </Col>
          <Outlet />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.history?.users,
    memoryNameInEditMode: state.history?.memoryNameInEditMode,
    showDetailpage: state.history?.showDetailpage,
    currentDeviceToken: state.history?.currentDeviceToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showMemoryNameInEditMode: (memoryNameInEditMode) =>
      dispatch(showMemoryNameInEditMode(memoryNameInEditMode)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserMemory);
