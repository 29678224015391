import Api from "../Api"
export const shareMemory = (dataObj)=>{
  return Api.post("share",dataObj)
}

export const deleteShareMemory = (userId,memoryId)=>{
   return Api.delete(`share?user_id=${userId}&memory_id=${memoryId}`)
    .then((res) => res.data)
    .catch((error) => error);
 
}