import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import { useParams } from "react-router-dom";
import { phone } from "phone";
import { shareMemory, deleteShareMemory } from "../service/ShareService";
import { setSharedData } from "../redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
const ShareWithContactModal = (props) => {
  const {
    showshareWithContactModal,
    getMemoryDetail,
    closeshareWithContactModal,
  } = props;

  let params = useParams();

  const memoryId = params.memoryId;

  /* const "toogle" store onblur event status*/
  const [toogle, settoogle] = useState(false);

  /* const "country" store country Name */
  const [country, setcountry] = useState("us");

  /*const "number" store number   */
  const [number, setnumber] = useState(null);

  /*const "allNumber" store all number  */
  const [allNumber, setallNumber] = useState([]);

  /* const "error" store flag of error*/
  const [error, seterror] = useState(false);

  /* const "diableButtton" store button disable status  */
  const [diableButtton, setdiableButtton] = useState(true);

  /* const "rerender" rerender the component  */
  const [rerender, setrerender] = useState(true);

  /* const "dialCode" store the dial code */
  const [dialCode, setdialCode] = useState("");

  /* const "sharingToggle" toggle for sharing button*/
  const [sharingToggle, setsharingToggle] = useState(false);

  /* const "duplicatedNumberToggle" toggle for duplicate number  */
  const [duplicatedNumberToggle, setduplicatedNumberToggle] = useState(false);

  useEffect(() => {
    if (props.sharedData) {
      props.sharedData.map((sharedData, index) => {
        const updateNumber = {};
        updateNumber.country_code = sharedData.country_code;
        updateNumber.phone_number = sharedData.phone_number;
        updateNumber.user_id = sharedData.user_id;
        allNumber.push(updateNumber);
        setrerender(!rerender);
      });
    }
  }, []);

  /**
   * This Function handle phone Input field
   * @param {string} phonenumber
   * @param {object} value
   */
  const handleInputOnChange = (phonenumber, value) => {
    setduplicatedNumberToggle(false);
    console.log(phonenumber);
    setdialCode(value.dialCode);
    setcountry(value.countryCode);

    const newNumber = "+" + phonenumber;
    if (newNumber !== number) {
      setnumber("+" + phonenumber);
      const phoneNumberStatus = phone(phonenumber, {
        country: value.countryCode,
      });
      if (phoneNumberStatus.isValid) {
        allNumber.map((number, index) => {
          console.log("91" + number.phone_number === phonenumber)
          if ("91" + number.phone_number === phonenumber) {
            setduplicatedNumberToggle(true);
          }
        });
        setdiableButtton(false);
        seterror(false);
      } else {
        setduplicatedNumberToggle(false);
        setdiableButtton(true);
        seterror(true);
      }
    }
  };

  /**
   * This function will add number
   * @param {number} number
   */
  const addNumber = (number) => {
    settoogle(false);
    setdiableButtton(true);
    const updateNumber = {};
    updateNumber.country_code = "+" + dialCode;
    updateNumber.phone_number = number.replace(`+${dialCode}`, "");
    updateNumber.user_id = "";
    allNumber.push(updateNumber);
    setrerender(!rerender);
    setnumber(dialCode);
  };

  /**
   * This function will delete the number
   * @param {number} index
   */
  const deleteNumber = (index, userId) => {
    allNumber.splice(index, 1);
    setrerender(!rerender);
    if (userId) {
      deleteShareMemory(userId, memoryId)
        .then((response) => {
          if (response.status == 200) {
            console.log("response", response);
          } else {
            console.log("response", response);
            toast.error("Something went wrong", {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          console.log("response", error);
          toast.error("Something went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    }
  };

  /**
   * This function will share memory with contact
   */
  const shareDetailWithNumber = () => {
    setsharingToggle(true);
    const userId = localStorage.getItem("userId");
    allNumber.map((number,index) =>{
      console.log(number)
      number.user_id ?
       allNumber.splice(index,1):
       delete number.user_id
    }
    );
    const dataObj = {
      user_id: userId,
      memory_id: memoryId,
      share_with: allNumber,
      share_rights: "ro",
    };
    shareMemory(dataObj)
      .then((response) => {
        setsharingToggle(true);
        if (response.status === 200) {
          toast.success(response.data?.message, {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
          getMemoryDetail();
          closeshareWithContactModal();
        } else {
          closeshareWithContactModal();
        }
      })
      .catch((error) => {
        setsharingToggle(true);
        closeshareWithContactModal();
      });
  };

  return (
    <Modal isOpen={showshareWithContactModal}>
      <ModalHeader toggle={closeshareWithContactModal}>
        <p>Share</p>
      </ModalHeader>
      <ModalBody>
        <div className="inputContainer">
          <div style={{ marginBottom: "15px" }}>
            {allNumber.length > 0 &&
              allNumber.map((number, index) => {
                return (
                  <div key={index} className="contact_item">
                    <i
                      className="fa fa-phone"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                    ></i>
                    <div style={{ flex: 1, paddingLeft: "5px" }}>
                      {number.phone_number}{" "}
                    </div>
                    <div onClick={() => deleteNumber(index, number.user_id)}>
                      <i className="far fa-trash-alt text-danger"></i>
                    </div>
                  </div>
                );
              })}
          </div>
          <strong>Enter Phone Number</strong>
          <div className="d-flex flex-wrap sharedContainer">
            <div>
              <PhoneInput
                style={{ marginTop: "5px", maxWidth: "230px" }}
                country={country}
                value={number}
                placeholder="Phone Number"
                countryCodeEditable={false}
                enableSearch={true}
                onChange={(phonenumber, value) =>
                  handleInputOnChange(phonenumber, value)
                }
                onBlur={() => settoogle(true)}
              />
              <div style={{ marginBottom: "20px" }}>
                {toogle && error ? (
                  <p className="text-danger f-12">Please enter valid number</p>
                ) : (
                  ""
                )}
              </div>
              <div style={{ marginBottom: "20px" }}>
                {duplicatedNumberToggle ? (
                  <p className="text-danger f-12">
                    This Number is already Added
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Button
              onClick={() => addNumber(number)}
              className="custom-btn"
              size="sm"
              disabled={diableButtton||duplicatedNumberToggle}
              style={{ height: "35px", marginLeft: "10px" }}
            >
              Add
            </Button>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={shareDetailWithNumber}
          className="custom-btn"
          size="sm"
          disabled={allNumber.length > 0 ? false : true}
        >
          {sharingToggle ? "Sharing" : "Share"}
        </Button>
        <Button
          onClick={closeshareWithContactModal}
          className="custom-btn"
          size="sm"
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    sharedData: state.history.sharedData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSharedData: (sharedData) => dispatch(setSharedData(sharedData)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareWithContactModal);
