import Api from "../Api";

export const addMemory = (dataObj)=>{
  return Api.post("memory",dataObj).then(res=>res.data).catch(err=>err)
}
export const updateMemory = (dataObj)=>{
  return Api.put("memory",dataObj).then(res=>res.data).catch(err=>err)
}
export const deleteMedia = (mediaId)=>{
  return Api.delete(`removeMedia?mediaId=${mediaId}`)
}
export const allMemory = (userdata) => {
  return Api.post("history", userdata)
    .then((res) => res.data)
    .catch((error) => error);
};
export const memoryDetail = (memoryId,userId) => {
  return Api.get(`memoryDetails?memoryId=${memoryId}&userId=${userId}`)
    .then((res) => res.data)
    .catch((error) => error);
};
export const deleteMemory = (memoryId) => {
  return Api.delete(`memory?memoryId=${memoryId}`)
      .then((res) => res.data)
      .catch((error) => error);
  };