import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Col } from "reactstrap";
import ListDetail from "../list/ListDetail";
import MemoryDetail from "../memory/MemoryDetail";
import { connect } from "react-redux";
import { showhistoryDetailOnMobile } from "../redux";
import { fetchUsersAllMemory } from "../redux";

const UserMemoryDetail = (props) => {
  let params = useParams();

  const memoryId = params.memoryId;

  const location = useLocation();

  const { type, memoryNameInEditMode } = location?.state;

  useEffect(() => {
    props.showhistoryDetailOnMobile();
  }, [memoryId]);

  return (
    <Col lg="9" md="8" className="col-12">
      {type === "list" ? (
        <ListDetail
          listId={memoryId}
          memoryNameInEditMode={memoryNameInEditMode}
        />
      ) : (
        <MemoryDetail
          memoryId={memoryId}
          showNameInEditMode={memoryNameInEditMode}
        />
      )}
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.history?.users,
    showhistoryDetailpage: state.history?.showDetailpage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsersAllMemory: () => dispatch(fetchUsersAllMemory()),
    showhistoryDetailOnMobile: () => dispatch(showhistoryDetailOnMobile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserMemoryDetail);
