import React, { useState, useEffect } from "react";
import { memoryDetail } from "../service/MemoryService";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import DetailHeader from "../history/DetailHeader";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  createNewSection,
  updateSection,
  deleteSection,
} from "../service/SectionService";
import {
  updateContentStatus,
  deleteItem,
  moveItem,
} from "../service/ContentService";
import { updateMemory } from "../service/MemoryService";
import { connect } from "react-redux";
import { authentication } from "../firebase/firebase";
import LogoutConfirm from "../auth/LogoutConfirm";
import DeleteItemModal from "./DeleteItemModal";
import DeleteSectionModal from "./DeleteSectionModal";
import ShareList from "./ShareList";
import { toast } from "react-toastify";
import {
  fetchUsersAllMemory,
  setReminderTime,
  setReminderId,
  hidehistoryDetailOnMobile,
  setReminderRepeatType,
  setcustomRepeatDuration,
  setrepeatOccurence,
  setcustomReminderUnit,
  setcustomReminderEndDateType,
  setMemoryId,
  setMemoryName,
  setTotalShared,
  setSharedData,
} from "../redux";
import Linkify from "react-linkify";
import Reminder from "../reminder/Reminder";
import moment from "moment";
import AddSectionItem from "./AddSectionItem";
import ShareWithContactModal from "../share/ShareWithContactModal";

const ListDetail = (props) => {
  const { listId, memoryNameInEditMode } = props;
  console.log("totalShared", props.totalShared);
  console.log("totalShared", props);
  const navigate = useNavigate();

  /* cosnt "uId" store userId of user */
  const [uId, setuId] = useState("");

  /* const "loading" responsible for loader */
  const [loading, setloading] = useState(true);

  /*const "listDetail" store detail of list   */
  const [listDetail, setlistDetail] = useState();

  /* const "listName" store Name of list */
  const [listName, setlistName] = useState("");

  /* const "showListNameInEditMode" open list Name in edit mode */
  const [showListNameInEditMode, setshowListNameInEditMode] = useState(false);

  /* const "showSectionNameInEditMode" open section Name in edit Mode  */
  const [showSectionNameInEditMode, setshowSectionNameInEditMode] = useState(
    false
  );

  /* const "sectionId" store section Id*/
  const [sectionId, setsectionId] = useState("");

  /* const "sectionName" store section Name */
  const [sectionName, setsectionName] = useState("");

  /* const "setdeletedSectionId" store section id of deteing section */
  const [deletedSectionId, setdeletedSectionId] = useState("");

  /* const "showDeleteSectionModal" show delete modal */
  const [showDeleteSectionModal, setshowDeleteSectionModal] = useState(false);

  /* const  "setdeletedItemId" store delete item Id*/
  const [deletedItemId, setdeletedItemId] = useState("");

  /* conat "setshowDeleteItemModal" open item Delete Modal  */
  const [showDeleteItemModal, setshowDeleteItemModal] = useState(false);

  /* const "selectItemId" store item Id of select item   */
  const [selectItemId, setselectItemId] = useState("");

  /* const "ItemName" store item Name*/
  const [itemName, setitemName] = useState();

  /* const "showItemNameInEditModa" open in edit mode */
  const [showItemNameInEditModa, setshowItemNameInEditModa] = useState();

  /* const  "itemId" store itemId of section*/
  const [itemId, setItemId] = useState(null);

  /* const "rerender" render the component */
  const [rerender, setrerender] = useState(true);

  /* const "showshareListModal" hide and show list modal*/
  const [showshareListModal, setshowshareListModal] = useState(false);

  /* const "showLogoutConfirm" responsible open for login */
  const [showLogoutConfirm, setshowLogoutConfirm] = useState(false);

  /* const "deleteLoader" show the loader for deleting */
  const [deleteLoader, setdeleteLoader] = useState(false);

  /* cosnt "shareListData" store share data of list */
  const [shareListData, setshareListData] = useState();

  /* const "completedItemId" store complete item Id */
  const [completedItemId, setCompletedItemId] = useState(null);

  /* const "deletedSectionName" store section Name */
  const [deletedSectionName, setdeletedSectionName] = useState("");

  /* const "showshareWithContactModal" hide and show share memory contact modal */
  const [showshareWithContactModal, setshowShareWithContactModal] = useState(
    false
  );

  /* const "dropdownOpen" open dropdown of upload file */
  const [shareDropdownToggle, setshareDropdownToggle] = useState(false);

  /* const "dropdownOpen" open dropdown of upload file */
  const [showUpdateSharedUserModal, setshowUpdateSharedUserModal] = useState(
    false
  );

  useEffect(() => {
    let userId = localStorage.getItem("userId");
    setuId(userId);
    setlistDetail([]);
    setloading(true);
    setlistName("");
    fetchListDetail(true);
  }, [listId]);

  /**
   * This function will fetch list Detail
   */
  const fetchListDetail = (toggle) => {
    let userId = localStorage.getItem("userId");
    memoryDetail(listId, userId)
      .then((response) => {
        if (response.status === 200) {
          setlistDetail(response.data);
          if (response.data?.length > 0) {
            props.setMemoryId(response.data[0].memoryId);
            props.setMemoryName(
              response.data[0].memoryText
                ? response.data[0].memoryText
                : "New List"
            );
            if (response.data[0]?.reminder?.length > 0) {
              props.setReminderTime(
                response.data[0]?.reminder[0].reminder_time
              );
              props.setReminderId(response.data[0]?.reminder[0].reminder_id);
              props.setReminderRepeatType(
                response.data[0]?.reminder[0].repeat_type
              );
              props.setcustomRepeatDuration(
                response.data[0]?.reminder[0].custom_repeat_duration
              );
              props.setrepeatOccurence(
                response.data[0]?.reminder[0].repeat_occurrence
              );
              props.setcustomReminderUnit(
                response.data[0]?.reminder[0].custom_reminder_unit
              );
              props.setcustomReminderEndDateType(
                response.data[0]?.reminder[0].repeat_occurrence
                  ? "Occurrences"
                  : "Never"
              );
            } else {
              props.setReminderTime("");
              props.setReminderId("");
              props.setReminderRepeatType("");
              props.setcustomRepeatDuration("");
              props.setrepeatOccurence("");
              props.setcustomReminderUnit("");
              props.setcustomReminderEndDateType("");
            }
            if (response.data[0].share?.total_shared) {
              props.setTotalShared(response.data[0].share?.total_shared);
            } else {
              props.setTotalShared("");
            }
            if (response.data[0].share?.shared_data?.length > 0) {
              props.setSharedData(response.data[0].share?.shared_data);
            } else {
              props.setSharedData([]);
            }
            if (memoryNameInEditMode && toggle) {
              setshowListNameInEditMode(true);
            } else {
              setshowListNameInEditMode(false);
            }
            setloading(true);
          }
        } else {
          toast.error(response?.data?.message, {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
        setloading(false);
      })
      .catch((error) => {
        toast.error("Some Thing went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
        setloading(false);
      });
  };

  useEffect(() => {
    window.onpopstate = (e) => {
      props.hidehistoryDetailOnMobile();
      const dataObj = {
        userId: uId,
        list_id: listId,
        input_text: listName,
      };
      if (listName !== "" && listDetail[0].memoryText !== listName) {
        updateMemory(dataObj)
          .then((response) => {
            if (response.status === 200) {
              props.fetchUsersAllMemory();
            }
            if (response.data?.status === 404) {
              toast.success(response?.data?.message, {
                className: "toast-container_OTP",
                position: "top-right",
                autoClose: 3000,
              });
            }
          })
          .catch((error) => {
            toast.error("Something went wrong", {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
          });
      }
    };
  });

  const fetchUpdatedHistory = () => {
    props.hidehistoryDetailOnMobile();
    const dataObj = {
      userId: uId,
      list_id: listId,
      input_text: listName,
    };
    if (listName !== "" && listDetail[0].memoryText !== listName) {
      updateMemory(dataObj)
        .then((response) => {
          if (response.status === 200) {
          } else {
            toast.success(response?.data?.message, {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          toast.error("Something went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    }
    navigate("/memory");
  };

  /**
   * This function open List Name on edit mode
   * @param {string} listName
   */
  const openlistNameInEditMode = (listName) => {
    setlistName(listName);
    setshowListNameInEditMode(true);
  };

  /**
   * This function will update List Name
   * @param {object} event
   */
  const onChangeUpdateListName = (event) => {
    setlistName(event.target.value);
  };

  /**
   * This Function will save update List Name
   * @param {object} event
   */

  const saveListName = (event) => {
    const userId = localStorage.getItem("userId");
    if (
      event.target.value !== "" &&
      listDetail[0].memoryText !== event.target.value
    ) {
      const copyListText = listDetail[0].memoryText;
      listDetail[0].memoryText = event.target.value.trim();
      setlistDetail(listDetail);
      setshowListNameInEditMode(false);
      let listText;
      if (event.target.value === "New List") {
        listText = "";
      } else {
        listText = event.target.value.trim();
      }
      let dataObj = new FormData();
      dataObj.append("userId", userId);
      dataObj.append("memoryId", listId); //append the values with key, value pair
      dataObj.append("text", listText);
      updateMemory(dataObj)
        .then((response) => {
          if (response.status === 200) {
            props.fetchUsersAllMemory();
          }
          if (response.data?.status === 404) {
            setshowListNameInEditMode(false);
            listDetail[0].memoryText = copyListText;
            setlistDetail(listDetail);
            setrerender(!rerender);
            toast.success(response?.data?.message, {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          setshowListNameInEditMode(false);
          toast.error("Something went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    } else {
      setshowListNameInEditMode(false);
    }
  };

  /**
   * This function update List detail
   */
  const updateListDetail = () => {
    memoryDetail(listId, uId)
      .then((response) => {
        if (response.status === 200) {
          setlistDetail(response.data);
        }
        document.body.className = document.body.className.replace(
          "screen_loader",
          ""
        );
      })
      .catch((error) => {
        document.body.className = document.body.className.replace(
          "screen_loader",
          ""
        );
        toast.error("Some Thing went wrong3", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * This function will add a new section on list
   */
  const addNewSection = () => {
    document.body.classList.add("screen_loader");
    const dataObj = {
      memoryId: listId,
      sectionName: "New Section",
    };
    createNewSection(dataObj)
      .then((response) => {
        if ((response.status = 200)) {
          addSectionUpdateList();
        }
      })
      .catch((error) => {
        toast.error("Some Thing went wrong4", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * This function opne section Name in edit Mode
   */
  const addSectionUpdateList = () => {
    memoryDetail(listId, uId)
      .then((response) => {
        if (response.status === 200) {
          document.body.className = document.body.className.replace(
            "screen_loader",
            ""
          );
          if (response.data?.length > 0) {
            const newSectionId =
              response.data[0].sections[response.data[0].sections.length - 1]
                .sectionId;
            setsectionName("");
            setsectionId(newSectionId);
            setshowSectionNameInEditMode(true);
            setlistDetail(response.data);
          }
        }
      })
      .catch((error) => {
        document.body.className = document.body.className.replace(
          "screen_loader",
          ""
        );
        toast.error("Something went wrong5", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * open section Name in edit Mode
   * @param {string} sectionId
   * @param {string} sectionName
   */
  const openSectionNameInEditMode = (sectionId, sectionName) => {
    setsectionId(sectionId);
    setsectionName(sectionName);
    setshowSectionNameInEditMode(true);
  };

  /**
   *  This function will update section name
   * @param {object} event
   */
  const updateSectionName = (event) => {
    setsectionName(event.target.value);
  };

  /**
   * This function will capitalize the first lettar
   * @param {string} string
   * @returns
   */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * This function will Open section delete Modal
   * @param {string} sectionId
   */
  const opneSectionDeleteModal = (sectionId, sectionName) => {
    setdeletedSectionId(sectionId);
    setdeletedSectionName(sectionName);
    setshowDeleteSectionModal(true);
  };

  /**
   * This function will update section name when section Name on blur fired
   * @param {object} event
   * @param {string} sectionId
   */
  const saveSectionName = (event, sectionId) => {
    if (event.target.value !== "") {
      listDetail[0].sections.map((section) => {
        if (section.sectionId == sectionId) {
          section.sectionName = event.target.value;
        }
      });
      setlistDetail(listDetail);
      setshowSectionNameInEditMode(false);
      let dataObj = {
        memoryId: listId,
        sectionId: sectionId,
        sectionName: event.target.value.trim(),
      };
      updateSection(dataObj)
        .then((response) => {
          if (response.status === 200) {
            updateListDetail();
          }
        })
        .catch((error) => {
          updateListDetail();
          toast.error("Some Thing went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    } else {
      setshowSectionNameInEditMode(false);
    }
  };

  /**
   * This function will delete section of respective Id
   * @param {string} sectionId
   * @param {string} userId
   */
  const handledeleteSection = (sectionId, userId) => {
    setdeleteLoader(true);
    deleteSection(sectionId)
      .then((responce) => {
        if (responce.status === 200) {
          updateListDetail();
          setshowDeleteSectionModal(false);
          setdeleteLoader(false);
        }
      })
      .catch((error) => {
        toast.error("Some Thing went wrong7", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
        updateListDetail();
        setshowDeleteSectionModal(false);
        setdeleteLoader(false);
      });
  };

  /**
   * This function will send uncompleted item to completed
   * @param {string} itemId
   */
  const handleItemUpdate = (itemId, itemName) => {
    document.body.classList.add("screen_loader");
    setCompletedItemId(null);
    setItemId(itemId);
    const objData = {
      itemId: itemId,
      itemName: itemName,
      updateStatus: true,
    };
    updateContentStatus(objData)
      .then((response) => {
        updateListDetail(listId);
      })
      .catch((error) => {
        toast.error("Some Thing went wrong8", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
        document.body.className = document.body.className.replace(
          "screen_loader",
          ""
        );
      });
  };

  /**
   * This function will send completed item to uncompleted
   * @param {string} itemId
   */
  const handleCompletedItems = (itemId, itemName) => {
    document.body.classList.add("screen_loader");
    setItemId(null);
    setCompletedItemId(itemId);
    const objData = {
      itemId: itemId,
      itemName: itemName,
      updateStatus: true,
    };
    updateContentStatus(objData)
      .then((response) => {
        updateListDetail(listId);
      })
      .catch((error) => {
        toast.error("Some Thing went wrong9", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
        document.body.className = document.body.className.replace(
          "screen_loader",
          ""
        );
      });
  };

  /**
   * open item Name in edit mode
   * @param {string} itemId
   * @param {string} itemName
   */
  const openItemTextInEditModa = (itemId, itemName) => {
    setselectItemId(itemId);
    setitemName(itemName);
    setshowItemNameInEditModa(true);
  };

  /**
   * This function will update item name
   * @param {object} event
   * @param {string} itemId
   */
  const handleUpdateItemName = (event) => {
    setitemName(event.target.value);
  };

  /**
   * This function will save item Name
   * @param {object} event
   * @param {string} itemId
   */
  const saveItemName = (event, itemId) => {
    if (event.target.value !== "") {
      let copyItemName;
      listDetail[0].sections.map((section, index) => {
        section.items.map((item, index) => {
          if (itemId === item.list_item_id) {
            copyItemName = item.item_name;
            item.item_name = event.target.value;
          }
        });
      });
      setlistDetail(listDetail);
      setitemName("");
      setshowItemNameInEditModa(false);
      const objData = {
        itemId: itemId,
        itemName: event.target.value.trim(),
      };
      if (copyItemName !== event.target.value) {
        updateContentStatus(objData)
          .then((response) => {
            if (response.status === 200) {
              updateListDetail();
            } else {
              toast.error("Some Thing went wrong", {
                className: "toast-container_OTP",
                position: "top-right",
                autoClose: 3000,
              });
            }
          })
          .catch((error) => {
            toast.error("Some Thing went wrong", {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
            updateListDetail();
          });
      }
    } else {
      setshowItemNameInEditModa(false);
    }
  };

  const grid = 0;
  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: 1,
      margin: `0 0 ${grid}px 0`,
      // textAlign: "right",
      // change background colour if dragging
      background: isDragging ? "white" : "#f7f7f7",
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  /**
   * This Function will drag the item in same and another section
   * @param {object} result
   * @returns
   */
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      let contentIdArray = [];
      listDetail[0].sections.map((section, index) => {
        if (section.sectionId === destination.droppableId) {
          let updatedSection = section;
          const [removed] = updatedSection.items.splice(source.index, 1);
          updatedSection.items.splice(destination.index, 0, removed);
          updatedSection.items.map((item, index) => {
            contentIdArray.push(item.list_item_id);
          });
          const contentIdStringArray = contentIdArray.join();
          const objData = {
            itemIds: contentIdStringArray,
            newSectionId: result.destination.droppableId,
          };
          moveItem(objData)
            .then((res) => {
              updateListDetail(listId);
            })
            .catch((error) => console.log(error));
        }
      });
    } else {
      let sourceClone;
      let destinationClone;
      let contentIdArray = [];
      listDetail[0].sections.map((section, index) => {
        if (section.sectionId === source.droppableId) {
          sourceClone = section;
        }
        if (section.sectionId === destination.droppableId) {
          destinationClone = section;
        }
      });
      const [removed] = sourceClone.items.splice(source.index, 1);
      destinationClone.items.splice(destination.index, 0, removed);
      listDetail[0].sections.map((section, index) => {
        if (section.sectionId === result.destination.droppableId) {
          section.items.map((item, index) => {
            contentIdArray.push(item.list_item_id);
          });
        }
      });
      const contentIdStringArray = contentIdArray.join();
      const objData = {
        itemIds: contentIdStringArray,
        newSectionId: result.destination.droppableId,
      };
      moveItem(objData)
        .then((res) => {
          updateListDetail(listId);
        })
        .catch((error) => console.log(error));
    }
  };

  /**
   * This funciton will delete item of section
   * @param {string} itemId
   */
  const handledeleteItem = (itemId) => {
    setdeleteLoader(true);
    deleteItem(itemId)
      .then((response) => {
        if (response.status === 200) {
          updateListDetail();
          setshowDeleteItemModal(false);
          setdeleteLoader(false);
        } else {
          setshowDeleteItemModal(false);
          setdeleteLoader(false);
          toast.error(response.data?.message, {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        setshowDeleteItemModal(false);
        setdeleteLoader(false);
        toast.error(error.data?.message, {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  /**
   * This Function will open dlete item modal
   * @param {string} itemId
   */
  const opneItemDeleteModal = (itemId, itemName) => {
    setdeletedItemId(itemId);
    setshowDeleteItemModal(true);
  };

  /**
   * This function will Open share file modal
   */
  const openAllshareFile = () => {
    let shareListData = "List : ";
    shareListData += listDetail[0].memoryText + "\n";
    shareListData += "\n";
    if (listDetail[0].sections?.length > 0) {
      let sectionData = "";
      listDetail[0].sections.map((section, index) => {
        sectionData += "\n" + section.sectionName + "\n";
        {
          section.items.map((item, index) => {
            {
              sectionData += "  " + "⦾ " + item.item_name + "\n";
            }
          });
          section.completedItems.map((completeditem, index) => {
            {
              sectionData += "  " + "⦿ " + completeditem.item_name + "\n";
            }
          });
        }
      });
      shareListData += sectionData;
    }
    setshareListData(shareListData);
    setshowshareListModal(true);
  };

  const giveSomeStyleToName = (memoryText) => {
    const text = memoryText.split(" ");
    if (text?.length > 0) {
      if (text?.length > 40) {
        return (
          <p style={{ wordBreak: "break-all", whiteSpace: "preLine" }}>
            {text.map((text, index) => {
              return (
                <span style={{ fontWeight: index < 5 ? "bold" : "" }}>
                  {text + " "}
                </span>
              );
            })}
          </p>
        );
      } else {
        return (
          <p>
            {text.map((text, index) => {
              return (
                <span style={{ fontWeight: index < 1 ? "bold" : "" }}>
                  {text + " "}
                </span>
              );
            })}
          </p>
        );
      }
    }
  };

  /**
   *
   * @returns tommorrow date
   */
  const getTomorrowDate = () => {
    let now = new Date();
    now.setDate(now.getDate() + 1);
    now.setHours(8);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  };

  /**
   * This function will formate time of Reminder
   * @param {string} reminder_time
   * @returns
   */
  const formateReminderTime = (reminder_time) => {
    const date = new Date();
    const selectedYear = moment(reminder_time).format("YYYY");
    const currentYear = date.getFullYear().toString();
    if (
      moment(reminder_time).format("YYYY-MM-DD") ==
      moment(date).format("YYYY-MM-DD")
    ) {
      return "Today " + moment(reminder_time).format("h:mm a");
    }
    if (
      moment(reminder_time).format("YYYY-MM-DD") ==
      moment(getTomorrowDate()).format("YYYY-MM-DD")
    ) {
      return "Tomorrow " + moment(reminder_time).format("h:mm a");
    }

    if (currentYear === selectedYear) {
      return moment(reminder_time).format("MMM Do, h:mm a");
    } else {
      return moment(reminder_time).format("MMM Do YYYY, h:mm a");
    }
  };

  /**
   * This function will logout the user
   */
  const Logout = () => {
    authentication.signOut();
  };

  return (
    <div>
      <DetailHeader fetchUpdatedHistory={fetchUpdatedHistory} />
      {loading ? (
        <div className="custom-loader">
          <Spinner animation="grow" size="sm" />
        </div>
      ) : (
        <div>
          {listDetail.length > 0 ? (
            <div>
              <div
                className="d-flex justify-content-end align-items-start px-3 py-2"
                style={{ borderBottom: "2px solid #ededed" }}
              >
                <Reminder getMemoryDetail={fetchListDetail} />

                <div>
                  <Button
                    onClick={addNewSection}
                    className="btn-sm custom-btn"
                    style={{ minWidth: "115px" }}
                  >
                    <i className="fas fa-plus mr-1" /> Add Section
                  </Button>
                </div>
                <div style={{ paddingLeft: "5px" }}>
                  <ButtonDropdown
                    isOpen={shareDropdownToggle}
                    toggle={() => setshareDropdownToggle(!shareDropdownToggle)}
                    size="sm"
                  >
                    <DropdownToggle caret>
                      <i
                        className="fas fa-share-alt"
                        style={{ marginRight: "5px" }}
                      ></i>{" "}
                      Share
                    </DropdownToggle>

                    <DropdownMenu>
                      <DropdownItem onClick={openAllshareFile}>
                        Other
                      </DropdownItem>

                      <DropdownItem divider />
                      <DropdownItem
                        onClick={() => setshowShareWithContactModal(true)}
                      >
                        Share to collaborate
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </div>
              <div className="all_history_container">
                <div
                  className="px-3 py-3"
                  style={{ borderBottom: "2px solid #ededed" }}
                >
                  <div className="d-flex" style={{ margin: "10px 0px" }}>
                    {showListNameInEditMode ? (
                      <TextareaAutosize
                        className="details_history_name"
                        spellCheck="false"
                        placeholder="New List"
                        value={capitalizeFirstLetter(listName)}
                        onChange={(event) => {
                          onChangeUpdateListName(event);
                        }}
                        onBlur={(event) => saveListName(event)}
                        ref={(ref) => ref && ref.focus()}
                        onFocus={(e) =>
                          e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                          )
                        }
                      />
                    ) : (
                      <Linkify>
                        <div
                          onClick={() =>
                            openlistNameInEditMode(
                              listDetail[0].memoryText === ""
                                ? "New List"
                                : listDetail[0].memoryText
                            )
                          }
                          className="history_name"
                          style={{
                            wordBreak: "break-all",
                            whiteSpace: "pre-wrap",
                            flex: 1,
                          }}
                        >
                          {listDetail[0].memoryText === ""
                            ? "New List"
                            : listDetail[0].memoryText}
                        </div>
                      </Linkify>
                    )}
                  </div>
                  <div>
                    {props.reminderTime && (
                      <div className="memory_reminder_time">
                        <i
                          className="far fa-clock"
                          style={{ marginRight: "3px", fontSize: "12px" }}
                        ></i>
                        <p>{formateReminderTime(props.reminderTime)}</p>
                      </div>
                    )}
                  </div>
                 
                    {props.totalShared && (
                       <div
                       className="my-2 p-2 sharedData"
                       onClick={() => setshowShareWithContactModal(true)}
                     >
                      <div>
                        <i
                          className="fas fa-share-alt"
                          style={{ marginRight: "5px" }}
                        ></i>
                        Shared with {props.totalShared} people
                      </div>
                      </div>
                    )}
                  
                </div>
                <div className=" px-3 py-2">
                  <DragDropContext onDragEnd={onDragEnd}>
                    {listDetail[0].sections.map((section, index) => {
                      return (
                        <div
                          key={index}
                          className="sectionContainer"
                          style={{ margin: "20px 0px" }}
                        >
                          <Droppable droppableId={section.sectionId}>
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <div
                                  className="d-flex mb-2 mt-2"
                                  style={{ backgroundColor: "#f5f5f5" }}
                                >
                                  <div style={{ flex: "1", display: "flex" }}>
                                    {showSectionNameInEditMode &&
                                    sectionId == section.sectionId ? (
                                      <TextareaAutosize
                                        className="section_textarea"
                                        type="text"
                                        placeholder="New Section"
                                        spellCheck="false"
                                        value={capitalizeFirstLetter(
                                          sectionName
                                        )}
                                        onChange={(event) => {
                                          updateSectionName(event);
                                        }}
                                        onBlur={(event) =>
                                          saveSectionName(
                                            event,
                                            section.sectionId
                                          )
                                        }
                                        ref={(ref) =>
                                          ref &&
                                          ref.focus({ preventScroll: false })
                                        }
                                        onFocus={
                                          (e) => console.log("onfocus")
                                          // e.currentTarget.setSelectionRange(
                                          //   e.currentTarget.value.length,
                                          //   e.currentTarget.value.length
                                          // )
                                        }
                                      />
                                    ) : (
                                      <Linkify>
                                        <div>
                                          {section.isDefault !== 1 ? (
                                            <div
                                              className="section_Name"
                                              onClick={() =>
                                                openSectionNameInEditMode(
                                                  section.sectionId,
                                                  section.sectionName
                                                )
                                              }
                                            >
                                              <p className="sections">
                                                {section.sectionName}
                                              </p>
                                            </div>
                                          ) : (
                                            <p className="sections">
                                              {section.sectionName}
                                            </p>
                                          )}
                                        </div>
                                      </Linkify>
                                    )}
                                  </div>
                                  {section.isDefault !== 1 && (
                                    <span
                                      onClick={() =>
                                        opneSectionDeleteModal(
                                          section.sectionId,
                                          section.sectionName
                                        )
                                      }
                                    >
                                      <i className="far fa-trash-alt text-danger"></i>
                                    </span>
                                  )}
                                </div>
                                <div className="sub-container">
                                  {section.items.map((item, index) => {
                                    return (
                                      <Draggable
                                        key={item.list_item_id}
                                        draggableId={item.list_item_id}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                            key={index}
                                          >
                                            <div
                                              className="d-flex mb-1 mt-1"
                                              style={{ paddingLeft: "5px" }}
                                            >
                                              <div
                                                {...provided.dragHandleProps}
                                              >
                                                <i
                                                  className="fas fa-grip-vertical"
                                                  style={{
                                                    fontSize: "14px",
                                                    margin: "3px 0",
                                                  }}
                                                ></i>
                                              </div>
                                              <div className="custom-check">
                                                <input
                                                  type="checkbox"
                                                  className="itemCheckbox "
                                                  style={{
                                                    margin: "3px 10px",
                                                  }}
                                                  value={
                                                    itemId === item.list_item_id
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    itemId === item.list_item_id
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(event) =>
                                                    handleItemUpdate(
                                                      item.list_item_id,
                                                      item.item_name
                                                    )
                                                  }
                                                />
                                              </div>

                                              {showItemNameInEditModa &&
                                              selectItemId ===
                                                item.list_item_id ? (
                                                <TextareaAutosize
                                                  type="text"
                                                  spellCheck="false"
                                                  className="details_item_name"
                                                  value={capitalizeFirstLetter(
                                                    itemName
                                                  )}
                                                  onChange={(event) =>
                                                    handleUpdateItemName(event)
                                                  }
                                                  onBlur={(event) =>
                                                    saveItemName(
                                                      event,
                                                      item.list_item_id
                                                    )
                                                  }
                                                  ref={(ref) =>
                                                    ref && ref.focus()
                                                  }
                                                  onFocus={(e) =>
                                                    e.currentTarget.setSelectionRange(
                                                      e.currentTarget.value
                                                        .length,
                                                      e.currentTarget.value
                                                        .length
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <Linkify>
                                                  <div
                                                    onClick={() =>
                                                      openItemTextInEditModa(
                                                        item.list_item_id,
                                                        item.item_name
                                                      )
                                                    }
                                                    style={{ flex: 1 }}
                                                  >
                                                    <p className="items">
                                                      {item.item_name}
                                                    </p>
                                                  </div>
                                                </Linkify>
                                              )}

                                              <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  opneItemDeleteModal(
                                                    item.list_item_id,
                                                    item.item_name
                                                  )
                                                }
                                              >
                                                <i className="fa fa-times text-danger delete_item_btn delete_item"></i>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                </div>
                                {provided.placeholder}
                                <AddSectionItem
                                  sectionId={section.sectionId}
                                  userId={uId}
                                  setlistDetail={setlistDetail}
                                  listId={listId}
                                />

                                {section.completedItems?.length > 0 ? (
                                  <p
                                    style={{
                                      marginTop: "15px",
                                    }}
                                  >
                                    {"Completed"}
                                  </p>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="mt-2"
                                  style={{ paddingLeft: "5px" }}
                                >
                                  {section.completedItems?.length > 0 &&
                                    section.completedItems.map(
                                      (completedItem, index) => {
                                        return (
                                          <div
                                            className="d-flex mb-2 mt-2"
                                            style={{ paddingLeft: "10px" }}
                                            key={index}
                                          >
                                            <input
                                              type="checkbox"
                                              className="itemCheckbox"
                                              style={{
                                                marginLeft: "10px",
                                                marginTop: "4px",
                                              }}
                                              value={
                                                completedItemId ===
                                                completedItem.list_item_id
                                                  ? false
                                                  : true
                                              }
                                              checked={
                                                completedItemId ===
                                                completedItem.list_item_id
                                                  ? false
                                                  : true
                                              }
                                              onChange={(event) =>
                                                handleCompletedItems(
                                                  completedItem.list_item_id,
                                                  completedItem.item_name
                                                )
                                              }
                                            />
                                            <div
                                              className="ml-2"
                                              style={{ flex: 1 }}
                                            >
                                              <div
                                                style={{
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    wordBreak: "break-all",
                                                    whiteSpace: "pre-wrap",
                                                  }}
                                                >
                                                  {completedItem.item_name}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                opneItemDeleteModal(
                                                  completedItem.list_item_id,
                                                  completedItem.item_name
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-times text-danger delete_item_btn delete_item"
                                                style={{}}
                                              ></i>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            )}
                          </Droppable>
                        </div>
                      );
                    })}
                  </DragDropContext>
                </div>
              </div>
            </div>
          ) : (
            <div className="custom_no_datafound">
              <p>No Record Found</p>
            </div>
          )}
          {showLogoutConfirm && (
            <LogoutConfirm
              showLogoutConfirm={showLogoutConfirm}
              closeLogoutConfirm={() => setshowLogoutConfirm(false)}
              Logout={Logout}
            />
          )}
          {showDeleteSectionModal && (
            <DeleteSectionModal
              showDeleteSectionModal={showDeleteSectionModal}
              uId={uId}
              deletedSectionId={deletedSectionId}
              handledeleteSection={handledeleteSection}
              deleteLoader={deleteLoader}
              deletedSectionName={deletedSectionName}
              closeDeleteSectionModal={() => setshowDeleteSectionModal(false)}
            />
          )}
          {showDeleteItemModal && (
            <DeleteItemModal
              showDeleteItemModal={showDeleteItemModal}
              deletedItemId={deletedItemId}
              handledeleteItem={handledeleteItem}
              deleteLoader={deleteLoader}
              closedDeleteItemModal={() => setshowDeleteItemModal(false)}
            />
          )}
          {showshareListModal && (
            <ShareList
              showshareListModal={showshareListModal}
              shareListData={shareListData}
              closeshareListModal={() => setshowshareListModal(false)}
            />
          )}
          {showshareWithContactModal && (
            <ShareWithContactModal
              showshareWithContactModal={showshareWithContactModal}
              getMemoryDetail={fetchListDetail}
              closeshareWithContactModal={() =>
                setshowShareWithContactModal(false)
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sharedData: state.history.sharedData,
    totalShared: state.history.totalShared,
    userData: state.history?.users,
    reminderTime: state.history?.reminderTime,
    reminderId: state.history?.reminderId,
    showDetailpage: state.history?.showDetailpage,
    reminderRepeatType: state.history?.reminderRepeatType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSharedData: (sharedData) => dispatch(setSharedData(sharedData)),
    setTotalShared: (totalShared) => dispatch(setTotalShared(totalShared)),
    fetchUsersAllMemory: () => dispatch(fetchUsersAllMemory()),
    hidehistoryDetailOnMobile: () => dispatch(hidehistoryDetailOnMobile()),
    setReminderTime: (reminderTime) => dispatch(setReminderTime(reminderTime)),
    setReminderId: (reminderId) => dispatch(setReminderId(reminderId)),
    setReminderRepeatType: (reminderRepeatType) =>
      dispatch(setReminderRepeatType(reminderRepeatType)),
    setcustomRepeatDuration: (customRepeatDuration) =>
      dispatch(setcustomRepeatDuration(customRepeatDuration)),
    setrepeatOccurence: (repeatOccurence) =>
      dispatch(setrepeatOccurence(repeatOccurence)),
    setcustomReminderUnit: (customReminderUnit) =>
      dispatch(setcustomReminderUnit(customReminderUnit)),
    setcustomReminderEndDateType: (customReminderEndDateType) =>
      dispatch(setcustomReminderEndDateType(customReminderEndDateType)),
    setMemoryId: (memoryId) => dispatch(setMemoryId(memoryId)),
    setMemoryName: (memoryName) => dispatch(setMemoryName(memoryName)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListDetail);
