import React, { useState } from "react";
import { addItem } from "../service/ContentService";
import TextareaAutosize from "react-textarea-autosize";
import Linkify from "react-linkify";
import { memoryDetail } from "../service/MemoryService";
const AddSectionItem = (props) => {
  const { sectionId, userId, setlistDetail, listId } = props;

  /* const "showaddItemInEditMode" show item name in edit mode */
  const [showaddItemInEditMode, setshowaddItemInEditMode] = useState(false);

  /* const "showCopyItemText" toggle for showing copy item text */
  const [showCopyItemText, setshowCopyItemText] = useState(false);

  /* const "sectionItems" store section items  */
  const [sectionItems, setsectionItems] = useState([]);

  /* const "copyItemText"  store item text */
  const [itemText, setitemText] = useState("");

  /* const "copyItemText"  store copy of item text */
  const [copyItemText, setcopyItemText] = useState("");

  /**
   * This function will add new item
   */
  const addNewItem = () => {
    setshowaddItemInEditMode(true);
  };

  /**
   * This function will handle item text
   * @param {object} event
   */
  const handleItemText = (event) => {
    setitemText("");
    setshowaddItemInEditMode(false);
    setcopyItemText(event.target.value);
    setshowCopyItemText(true);
  };

  /**
   * This funciton will add new item
   * @param {object} event
   */
  const saveAddItem = (event) => {
    if (event.target.value !== "") {
      setshowCopyItemText(false);
      setcopyItemText("");
      const itemText = { itemText: event.target.value };
      sectionItems.push(itemText);
      setsectionItems(sectionItems);
      const objData = {
        userId: userId,
        sectionId: sectionId,
        itemName: event.target.value,
      };
      addItem(objData)
        .then((response) => {
          if (response.status === 200) {
            updateListDetail();
          }
        })
        .catch((error) => {
          // updateListDetail();
        });
    } else {
      setshowCopyItemText(false);
    }
  };

  /**
   * This function will update list detail
   */
  const updateListDetail = () => {
    memoryDetail(listId, userId)
      .then((response) => {
        if (response.status === 200) {
          sectionItems.shift();
          setsectionItems(sectionItems);
          setlistDetail(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * This function will capitalize the first lettar
   * @param {string} string
   * @returns
   */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      {sectionItems.length > 0 &&
        sectionItems.map((item, index) => {
          return (
            <div
              key={index}
              className="d-flex"
              style={{ padding: "5px 0px 5px 6px" }}
            >
              <div>
                <i
                  className="fas fa-grip-vertical"
                  style={{
                    fontSize: "14px",
                    margin: "3px 0",
                  }}
                ></i>
              </div>

              <div className="custom-check">
                <input
                  type="checkbox"
                  className="itemCheckbox "
                  style={{
                    margin: "3px 10px",
                  }}
                  disabled
                />
              </div>
              <Linkify>
                <div style={{ flex: 1 }}>
                  <p className="items">{item.itemText}</p>
                </div>
              </Linkify>

              <div
                style={{
                  paddingRight: "1px",
                }}
              >
                <i
                  className="fa fa-times text-danger delete_item_btn delete_item"
                  // className="far fa-times-circle text-danger"
                ></i>
              </div>
            </div>
          );
        })}

      {showCopyItemText && (
        <div className="d-flex" style={{ padding: "5px 0px 5px 6px" }}>
          <div>
            <i
              className="fas fa-grip-vertical"
              style={{
                fontSize: "14px",
                margin: "3px 0",
              }}
            ></i>
          </div>
          <div className="custom-check">
            <input
              type="checkbox"
              className="itemCheckbox "
              style={{
                margin: "3px 10px",
              }}
              disabled
            />
          </div>
          <TextareaAutosize
            className="details_item_name"
            spellCheck="false"
            value={capitalizeFirstLetter(copyItemText)}
            type="text"
            onChange={(event) => setcopyItemText(event.target.value)}
            onBlur={(event) => saveAddItem(event, sectionId)}
            ref={(ref) => ref && ref.focus()}
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              )
            }
          />

          <div
            style={{
              paddingRight: "1px",
            }}
          >
            <i className="fa fa-times text-danger delete_item_btn delete_item"></i>
          </div>
        </div>
      )}

      <div className="d-flex" style={{ padding: "5px 0px 6px 15px" }}>
        <i
          className="fas fa-plus"
          style={{
            marginRight: "10px",
            marginLeft: "10px",
            marginTop: "4px",
          }}
        />
        {showaddItemInEditMode ? (
          <TextareaAutosize
            placeholder="Add Item"
            spellCheck="false"
            className="details_item_name"
            value={capitalizeFirstLetter(itemText)}
            onChange={(event) => handleItemText(event)}
            autoFocus
          />
        ) : (
          <div
            onClick={() => addNewItem(sectionId)}
            style={{ color: "#636c72" }}
          >
            Add Item
          </div>
        )}
      </div>
    </>
  );
};

export default AddSectionItem;
