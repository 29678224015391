import axios from "axios";
import moment from "moment";
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  SHOW_MOBILE_HISTORY_DETAIL,
  HIDE_MOBILE_HISTORY_DETAIL,
  REMINDER_TIME,
  REMINDER_ID,
  REMINDER_REPEAT_TYPE,
  CUSTOM_REPEAT_DURATION,
  REPEAT_OCCURENCE,
  CUSTOM_REMINDER_UNIT,
  CUSTOM_REMINDER_END_DATE_TYPE,
  MEMORY_NAME,
  MEMORY_ID,
  FETCH_MEMORY_DETAIL_REQUEST,
  FETCH_MEMORY_DETAIL_SUCCESS,
  FETCH_MEMORY_DETAIL_FAILURE,
  SHOW_MEMORY_NAME_IN_EDIT_MODE,
  FETCH_REMINDER_HISTORY_REQUEST,
  FETCH_REMINDER_HISTORY_SUCCESS,
  FETCH_REMINDER_HISTORY_FAILURE,
  REMINDER_MONTH,
  CURRENT_DEVICE_TOKEN,
  SESSIONID,
  TOTAL_SHARED,
  SHARED_DATA
} from "./historyTypes";
import { allMemory } from "../../service/HistoryService";
import { reminderHistory } from "../../service/ReminderService";
import { memoryDetail } from "../../service/MemoryService";
export const fetchUsersAllMemory = () => {
  let userId = localStorage.getItem("userId");
  const dataObj = {
    userId: userId,
    offset: "",
    limit: "",
    keyword: "",
  };
  return (dispatch) => {
    dispatch(fetchUsersRequest());
    allMemory(dataObj)
      .then((response) => {
        // response.data is the users
        const users = response.data;
        dispatch(fetchUsersSuccess(users));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchUsersFailure(error.message));
      });
  };
};

export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST,
  };
};

export const fetchUsersSuccess = (users) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: error,
  };
};

export const fetchMemoryDetail = () => {
  let userId = localStorage.getItem("userId");
  const dataObj = {
    user_id: userId,
    offset: "",
    limit: "",
    keyword: "",
  };
  return (dispatch) => {
    dispatch(fetchMemoryDetailRequest());
    memoryDetail(dataObj)
      .then((response) => {
        const memoryDetail = response.data;
        dispatch(fetchMemoryDetailSuccess(memoryDetail));
      })
      .catch((error) => {
        dispatch(fetchMemoryDetailFailure(error.message));
      });
  };
};

export const fetchMemoryDetailRequest = () => {
  return {
    type: FETCH_MEMORY_DETAIL_REQUEST,
  };
};

export const fetchMemoryDetailSuccess = (memoryDetail) => {
  return {
    type: FETCH_MEMORY_DETAIL_SUCCESS,
    payload: memoryDetail,
  };
};

export const fetchMemoryDetailFailure = (error) => {
  return {
    type: FETCH_MEMORY_DETAIL_FAILURE,
    payload: error,
  };
};

export const fetchReminderHistory = () => {
  let userId = localStorage.getItem("userId");
  var date = new Date();
  var monthFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var monthLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const dataObj = {
    userId: userId,
    startDate: moment(monthFirstDay).format("YYYY-MM-DD"),
    endDate: moment(monthLastDay).format("YYYY-MM-DD"),
    requestedTimeZone: "+05:30",
  };
  return (dispatch) => {
    dispatch(fetchReminderHistoryRequest());
    reminderHistory(dataObj)
      .then((response) => {
        const reminderHistory = response.data;

        dispatch(fetchReminderHistorySuccess(reminderHistory));
      })
      .catch((error) => {
        dispatch(fetchReminderHistoryFailure(error.message));
      });
  };
};

export const fetchReminderHistoryRequest = () => {
  return {
    type: FETCH_REMINDER_HISTORY_REQUEST,
  };
};

export const fetchReminderHistorySuccess = (reminderHistory) => {
  return {
    type: FETCH_REMINDER_HISTORY_SUCCESS,
    payload: reminderHistory,
  };
};

export const fetchReminderHistoryFailure = (error) => {
  return {
    type: FETCH_REMINDER_HISTORY_FAILURE,
    payload: error,
  };
};

export const showhistoryDetailOnMobile = () => {
  return {
    type: SHOW_MOBILE_HISTORY_DETAIL,
  };
};

export const hidehistoryDetailOnMobile = () => {
  return {
    type: HIDE_MOBILE_HISTORY_DETAIL,
  };
};

export const setReminderTime = (reminderTime) => {
  return {
    type: REMINDER_TIME,
    payload: reminderTime,
  };
};

export const setReminderId = (reminderId) => {
  return {
    type: REMINDER_ID,
    payload: reminderId,
  };
};

export const setReminderRepeatType = (reminderRepeatType) => {
  return {
    type: REMINDER_REPEAT_TYPE,
    payload: reminderRepeatType,
  };
};
export const setcustomRepeatDuration = (customRepeatDuration) => {
  return {
    type: CUSTOM_REPEAT_DURATION,
    payload: customRepeatDuration,
  };
};

export const setrepeatOccurence = (repeatOccurence) => {
  return {
    type: REPEAT_OCCURENCE,
    payload: repeatOccurence,
  };
};

export const setcustomReminderUnit = (customReminderUnit) => {
  return {
    type: CUSTOM_REMINDER_UNIT,
    payload: customReminderUnit,
  };
};
export const setcustomReminderEndDateType = (customReminderEndDateType) => {
  return {
    type: CUSTOM_REMINDER_END_DATE_TYPE,
    payload: customReminderEndDateType,
  };
};

export const setMemoryId = (memoryId) => {
  return {
    type: MEMORY_ID,
    payload: memoryId,
  };
};

export const setMemoryName = (memoryName) => {
  return {
    type: MEMORY_NAME,
    payload: memoryName,
  };
};

export const showMemoryNameInEditMode = (memoryNameInEditMode) => {
 return {
    type: SHOW_MEMORY_NAME_IN_EDIT_MODE,
    payload: memoryNameInEditMode,
  };
};

export const setReminderMonth = (reminderMonth) => {
  return {
    type: REMINDER_MONTH,
    payload: reminderMonth,
  };
};

export const setCurrentDeviceToken = (currentDeviceToken) => {
  return {
    type: CURRENT_DEVICE_TOKEN,
    payload: currentDeviceToken,
  };
};
export const setSessionId = (sessionId) => {
  return {
    type: SESSIONID,
    payload: sessionId,
  };
};

export const setTotalShared = (totalShared) => {
  return {
    type: TOTAL_SHARED,
    payload: totalShared,
  };
};

export const setSharedData = (sharedData) => {
  return {
    type: SHARED_DATA,
    payload: sharedData,
  };
};