import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyBONczqj973nwbSCNopSN8XIDUAvG6f7Pw",
  authDomain: "note2self-55179.firebaseapp.com",
  projectId: "note2self-55179",
  storageBucket: "note2self-55179.appspot.com",
  messagingSenderId: "845632231338",
  appId: "1:845632231338:web:4d1e357843f60d515afdd3",
  measurementId: "G-E94G38P6P5",
};
export const firebaseApp = initializeApp(firebaseConfig);
export const authentication = getAuth(firebaseApp);
const messaging = getMessaging();
export const requestForToken = (setcurrentToken) => {
  return getToken(messaging, {
    vapidKey:
      "BD4Eo2HdhBRakVxQ-Op0KmfaJyFEo_eI_Qz92HIWJdioM-H2JabvTaE6S_KSwRLhpy2TpmM81WY28ywkbIVphrU",
  })
    .then((currentToken) => {
      if (currentToken) {
        setcurrentToken(currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
