import Api from '../Api'
export const addItem = (objData)=>{
  return Api.post("item",objData).then((res)=>res.data)
}
export const updateContentStatus = (objData)=>{
  return Api.put("item",objData)
}

export const UpdateContentText= (objData)=>{
  return Api.put("update-content",objData)
}

export const deleteItem = (userId)=>{
  return Api.delete(`item?itemId=${userId}`)
}

export const moveItem = (objData)=>{
  return Api.put("move-item", objData).then(res=>res.data)
}